import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
} from '@mui/material';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
import { useDispatch, useSelector } from 'react-redux';
import { image_base_url } from 'src/services/endpoints';
import moment from 'moment'
import FullScreenDialog from 'src/components/fullDailog';
import CustomDropDown from 'src/components/dropdown';
import { useLocation } from 'react-router-dom';
import { getOrdersListThunk, sortOrderData } from 'src/redux/orders';
// ----------------------------------------------------------------------

const orderTypes = {
  "0": "Requested",
  "1": "Accepted",
  "2": "Rejected",
  "3": "Picked Up",
  "4": "Delieverd",
  "5": "Received",
  "6": "Cancelled"
}
const listData = [
  { value: 0, label: "Requested" },
  { label: "Accepted", value: 1 },
  { label: "Rejected", value: 2 },
  { label: "Picked Up", value: 3 },
  { label: "Delieverd", value: 4 },
  { label: "Received", value: 5 },
  { label: "Cancelled", value: 6 },
  { label: "All", value: 10 }

]

const TABLE_HEAD = [
  { id: 'source', label: 'Source', alignRight: false },
  { id: 'destination', label: 'Destination', alignRight: false },
  { id: 'shipper', label: 'Shipper', alignRight: false },
  { id: 'transporter', label: 'Transporter', alignRight: false },
  { id: 'date', label: "Date&Time", alignRight: false },
  { id: 'Price', label: "Price", alignRight: false },
  { id: 'byhome', label: "ByHome", alignRight: false },
  { id: 'status', label: "Status", alignRight: false },
  { id: 'createdAt', label: "Registered", alignRight: false },
  { id: 'action', label: "Action", alignRight: true },

];
const activeFilterList = [
  { label: "All", value: 'all' },
  { label: "Active", value: 'active' },
  { label: "Expired", value: 'expired' }
]

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

const getComparator = (order, orderBy) => {
  return (a, b) => {
    if (orderBy === 'source') {
      const aValue = a.from?.address || '';
      const bValue = b.from?.address || '';
      return order === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
    }
    if (orderBy === 'destination') {
      const aValue = a.to?.address || '';
      const bValue = b.to?.address || '';
      return order === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
    }
    if (orderBy === 'shipper') {
      const aValue = (a.status === 0 && a.requestBy === "transporter") ? a.requested_to?.email || '' : a.requested_by?.email || '';
      const bValue = (b.status === 0 && b.requestBy === "transporter") ? b.requested_to?.email || '' : b.requested_by?.email || '';
      return order === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
    }
    if (orderBy === 'transporter') {
      const aValue = (a.status === 0 && a.requestBy === "transporter") ? a.requested_to?.email || '' : a.requested_by?.email || '';
      const bValue = (b.status === 0 && b.requestBy === "transporter") ? b.requested_to?.email || '' : b.requested_by?.email || '';
      return order === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
    }
    if (orderBy === 'date') {
      const aValue = moment(a.toDate, "MMM, DD, YYYY hh:mm a").toDate();
      const bValue = moment(b.toDate, "MMM, DD, YYYY hh:mm a").toDate();
      return order === 'asc' ? aValue - bValue : bValue - aValue;
    }
    if (orderBy === 'Price') {
      const aValue = Number(a.subTotal) + Number(a.pickUp ? a.pickupCharges : 0) + Number(a.tax);
      const bValue = Number(b.subTotal) + Number(b.pickUp ? b.pickupCharges : 0) + Number(b.tax);
      return order === 'asc' ? aValue - bValue : bValue - aValue;
    }
    if (orderBy === 'status') {
      const aValue = String(a.status || '');
      const bValue = String(b.status || '');
      return order === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
    }
    if (orderBy === 'createdAt') {
      const aValue = moment(a.createdAt).toDate();
      const bValue = moment(b.createdAt).toDate();
      return order === 'asc' ? aValue - bValue : bValue - aValue;
    }
    return 0;
  };
};

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => (_user?.to?.address?.toLowerCase().indexOf(query.toLowerCase()) !== -1 || String(_user?.requested_to?.email).toLowerCase()?.includes(query.toLowerCase()) || String(_user?.requested_by?.email).toLowerCase().includes(query.toLowerCase())));
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function OrdersPage() {
  const [open, setOpen] = useState(null);

  const orders = useSelector(state => state.orders)?.list
  const sortDataRed = useSelector(state => state.orders)?.sortOrder

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState(sortDataRed?.ascDesc);
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState(sortDataRed?.orderBy);
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(200);
  const [selectedStatus, setSelectedStatus] = useState(10)
  // const users = useSelector(state => state.users)?.list
  const [selectedActive, setSelectedActive] = useState('all')

  const [selectedItem, setSelectedItem] = useState(null)
  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };
  const location = useLocation()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getOrdersListThunk(selectedActive))
  }, [location,selectedActive])
  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    console.log("property >> ", property)
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);

    dispatch(sortOrderData({
      orderBy: property,
      ascDesc: (isAsc ? 'desc' : 'asc'),
    }))
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = orders?.map((n) => n?.firstName);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, firstName) => {
    const selectedIndex = selected.indexOf(firstName);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, firstName);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - orders?.length) : 0;

  let filteredUsers = applySortFilter(orders, getComparator(order, orderBy), filterName);
  const isNotFound = !filteredUsers.length && !!filterName;
  filteredUsers = filteredUsers.filter(x => {
    if (selectedStatus == 10) {
      return true
    }
    return (x.status == selectedStatus)
  })

  return (
    <>
      <Helmet>
        <title>Packages | Lugit</title>
      </Helmet>

      <div className="" style={{ paddingInline: "20px" }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Orders
          </Typography>
          {/* <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
            New User
          </Button> */}
        </Stack>
        <Card>
          <div className="" style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
            <UserListToolbar showFilter={true} FilterComp={() => {
              return (
                <div style={{marginLeft:20}}>
                  <CustomDropDown list={listData} value={selectedStatus} setValue={d => setSelectedStatus(d)} label={"Status"} />
                </div>
              )
            }

            } placeholder={"Search Destination..."} numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />
            <div className="">
              <CustomDropDown label={"Active Status"} list={activeFilterList} value={selectedActive} setValue={setSelectedActive} />
            </div>
          </div>


          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={orders.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                    const { _id, from, to, dimensions, subTotal, toDate, goingBy, pickUp, pickupCharges, tax, status, requestBy, requested_by, requested_to, createdAt } = row;
                    const selectedUser = selected.indexOf(_id) !== -1;

                    return (
                      <TableRow hover key={_id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                        <TableCell padding="checkbox" sx={{ paddingLeft: "15px" }}>
                          {index + 1}
                        </TableCell>

                        <TableCell component="th" scope="row" padding="none">
                          {from?.address}
                          {/* 4848 County Rd 101, Minnetonka, MN 55345, USA */}
                        </TableCell>
                        <TableCell align="left">
                          {to?.address}
                          {/* Charlotte Douglas International Airport (CLT), 5501 Josh Birmingham Pkwy, Charlotte, NC 28208, USA */}
                        </TableCell>
                        <TableCell align="left">
                          {(status == 0 && requestBy == "transporter") ? requested_to?.email : requested_by?.email}
                        </TableCell>
                        <TableCell align="left">
                          {(status == 0 && requestBy == "transporter") ? requested_by?.email : requested_to?.email}
                        </TableCell>
                        <TableCell align="left">
                          {moment(toDate, "MMM, DD, YYYY hh:mm a").format("ddd, DD, MMM")}
                        </TableCell>
                        <TableCell align="left">
                          ${Number(subTotal) + Number(pickUp ? pickupCharges : 0) + Number(tax)}
                        </TableCell>
                        <TableCell align="left">
                          {row?.requestBy.charAt(0).toUpperCase()}{row?.requestBy.slice(1)}
                        </TableCell>
                        <TableCell align="left">
                          {orderTypes[`${status}`]}
                        </TableCell>

                        <TableCell align="left">
                          {moment(createdAt).format("MMM, DD, YYYY")}
                        </TableCell>
                        <TableCell align="right">
                          <Button onClick={() => {
                            setSelectedItem(row)
                          }} variant='contained' >Detail</Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 100, 200]}
            component="div"
            count={filteredUsers.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>

      </div>
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>
        <MenuItem sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>
      <FullScreenDialog item={selectedItem} setItem={setSelectedItem} />
    </>
  );
}
